.timeline {
  position: relative;

  @media screen and (min-width: 62em) {
    &:after {
      content: " ";
      position: absolute;
      left: 50%;
      right: 50%;
      height: 100%;
      border: 1px dashed rgba(192, 192, 192, 0.5);
    }
  }
}
